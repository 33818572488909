import { getOpenGraphImage } from 'utils/query';

export const metadata = {
  title: '🍒 RTPi - best RTP review site',
  description:
    'Find casinos with best RTPi: the worlds best source for casinos, slots, casino ratings and slots RTP.',
  url: 'https://rtpi.info/',
  siteName: 'RTPi Casino',
  locale: 'en_US',
  type: 'website',
  image: getOpenGraphImage({}),
};
