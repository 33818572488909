import { Layout } from 'components/layout';
import { CardsBig } from 'components/slider/cards/big';
import { CardsSkeleton } from 'components/slider/cards/medium';
import { CarouselPost } from './posts';
import { TitleHome } from './title';

import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from 'store/slices/auth';

import { CustomMetadata } from 'components/metadata';
import { metadata } from 'constants/open-graph';

import { CardsMediumCarousel } from 'components/slider/embla-home/card';

import { Routes } from 'constants/routes';
import {
  IBigWins,
  IBlogPost,
  IExtendedCollection,
  IProvider,
} from 'constants/types';
import { isMobile, useCasinos, useSlots } from 'hooks';
import { getCollectionIdentifier } from 'utils/slug';

import clsx from 'clsx';
import styles from './styles.module.css';
import { Analytics } from 'constants/analytics';

export const CasinosLine = ({
  collection,
}: {
  collection?: IExtendedCollection;
}) => {
  const { casinos, count } = useCasinos({
    collection: getCollectionIdentifier(collection ?? {}),
    skip: !!collection?.casinos?.length,
  });

  const mobile = isMobile();

  const OPTIONS = {
    align: mobile ? 'center' : 'start',
    dragFree: mobile,
    startIndex: 0,
  };

  if (!casinos?.length && !collection?.casinos?.length) {
    return <CardsSkeleton />;
  }

  return (
    <CardsMediumCarousel
      headerId={Analytics.home.openCollectionCard + `-${collection?.name}`}
      type="casino"
      mobile={mobile}
      name={collection?.name}
      count={casinos?.length ? count : collection?.count ?? '0'}
      slides={casinos?.length ? casinos : collection?.casinos ?? []}
      url={
        collection?.customUrl ??
        Routes.casinoCollections(getCollectionIdentifier(collection ?? {}))
      }
      //@ts-ignore
      options={OPTIONS}
    />
  );
};

export const SlotsLine = ({
  collection,
}: {
  collection: IExtendedCollection;
}) => {
  const { slots, count } = useSlots({
    skip: !!collection?.slots?.length,
    collection: getCollectionIdentifier(collection ?? {}),
  });

  const mobile = isMobile();

  const OPTIONS = {
    align: mobile ? 'center' : 'start',
    dragFree: mobile,
    startIndex: 0,
  };

  if (!slots?.length && !collection?.slots) {
    return <CardsSkeleton />;
  }

  return (
    <CardsMediumCarousel
      headerId={Analytics.home.openCollectionCard + `-${collection?.name}`}
      type="slot"
      name={collection?.name}
      count={slots?.length ? count : collection?.count ?? '0'}
      slides={slots?.length ? slots : collection?.slots ?? []}
      url={
        collection?.customUrl ??
        Routes.slotCollections(getCollectionIdentifier(collection))
      }
      //@ts-ignore
      options={OPTIONS}
    />
  );
};

export const ProvidersLine = ({ providers }: { providers: IProvider[] }) => {
  const mobile = isMobile();

  const OPTIONS = {
    align: mobile ? 'center' : 'start',
    dragFree: mobile,
    startIndex: 0,
  };

  return (
    <CardsMediumCarousel
      type="provider"
      name={'All Providers'}
      count={30}
      slides={providers?.length ? providers : []}
      url={Routes.providers}
      //@ts-ignore
      options={OPTIONS}
    />
  );
};

export type Props = {
  title: string;
  subtitle: string;
  posts: IBlogPost[];
  bigwins: IBigWins[];
  allProviders: IProvider[];
  countPosts: number;
  countBigwins: number;
  firstCollection: IExtendedCollection;
  collectionsArray: IExtendedCollection[];
};

export const MainPage = ({
  posts,
  title,
  bigwins,
  subtitle,
  countPosts,
  allProviders,
  countBigwins,
  firstCollection,
  collectionsArray,
}: Props) => {
  const isLoggedIn = useSelector(selectIsLoggedIn());

  return (
    <div>
      <CustomMetadata
        canonicalUrl="/"
        metadata={metadata}
        titlePage={
          title ??
          'Casino Reviews - Best and Worst Players, Slot Machines, Games'
        }
        descriptionPage={
          subtitle ??
          'Explore casino reviews for the best and worst players, slot machines, games, and more. Get insights into the casino world.'
        }
      />

      <Layout isMain hasFooter>
        <main className={clsx(styles.main, styles.background)}>
          <TitleHome isLogged={isLoggedIn} />
          <CasinosLine collection={firstCollection} />
          <CarouselPost countPosts={countPosts} posts={posts} />

          {collectionsArray?.map((item, index) => {
            if (item?.type === 'casino') {
              return <CasinosLine key={item?._id + index} collection={item} />;
            }
            if (item?.type === 'slot') {
              return <SlotsLine key={item?._id + index} collection={item} />;
            }

            return null;
          })}

          <CardsBig
            data={bigwins}
            name="Last slots big wins"
            count={countBigwins.toString() ?? '0'}
          />

          <ProvidersLine providers={allProviders} />
        </main>
      </Layout>
    </div>
  );
};
