/* eslint-disable @typescript-eslint/no-shadow */
import clsx from 'clsx';

import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';

import { Card as CardsMini } from 'components/slider/cards/mini';
import { Card as CardsMedium } from 'components/slider/cards/medium';
import { ICasino, IProvider, ISlot } from 'constants/types';

import { Header } from 'components/slider/header';

import { Icon } from 'common/icon';
import styles from './styles.module.css';
import { SeeMoreCard } from 'components/slider/cards/see-more-card';
import { ProviderCard } from 'components/template/providers/collection-providers';

type PropType = {
  mobile?: boolean;
  headerId?: string;
  url?: string;
  type?: 'casino' | 'slot' | 'provider';
  name?: string;
  slides: ICasino[] | ISlot[] | IProvider[];
  count?: number | string;
  options?: EmblaOptionsType | undefined;
};

type PropTypeArrow = PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
>;

export const PrevButton = (props: PropTypeArrow) => {
  const { children, ...restProps } = props;

  return (
    <button
      aria-label="Back"
      className={clsx(styles.button, styles.button__prev)}
      type="button"
      {...restProps}
    >
      <Icon.ArrowSlider />
      {children}
    </button>
  );
};

export const NextButton = (props: PropTypeArrow) => {
  const { children, ...restProps } = props;

  return (
    <button
      aria-label="Next"
      className={clsx(styles.button, styles.button__next)}
      type="button"
      {...restProps}
    >
      <Icon.ArrowSlider />
      {children}
    </button>
  );
};

export const CardsMediumCarousel = (props: PropType): JSX.Element => {
  const { headerId, slides, options, mobile, name, count, type, url } = props;

  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect);
    emblaApi.on('select', onSelect);
  }, [emblaApi, onSelect]);

  const seeMoreType = useMemo(() => {
    if (type === 'casino') {
      return 'medium';
    }
    if (type === 'slot') {
      return 'small';
    }
    if (type === 'provider') {
      return 'box';
    }
  }, [type]);

  if (!slides?.length) {
    return <></>;
  }

  return (
    <>
      <section className={styles.container}>
        <div className={styles.header}>
          <Header
            id={headerId}
            name={name}
            count={count}
            url={url?.length ? url : type === 'casino' ? 'casinos' : 'slots'}
          />
        </div>
        <div className={styles.embla}>
          {!prevBtnDisabled && (
            <div
              className={clsx({
                [styles.background_left]: true,
                [styles.background_slot_height]: type === 'slot',
              })}
            />
          )}
          <div className={styles.embla__viewport} ref={emblaRef}>
            <div className={styles.embla__container}>
              {slides?.map((item, index) => {
                switch (type) {
                  case 'casino':
                    return (
                      <CardsMedium
                        mobile={mobile}
                        key={item?._id + index}
                        data={item as ICasino}
                      />
                    );
                  case 'slot':
                    return (
                      <CardsMini key={item?._id + index} data={item as ISlot} />
                    );
                  case 'provider':
                    return (
                      <ProviderCard
                        key={item?._id?.toString()}
                        data={item as IProvider}
                      />
                    );

                  default:
                    return null;
                }
              })}

              {Number(count ?? 0) > 10 && (
                <SeeMoreCard
                  count={Number(count) - 10}
                  url={
                    url?.length ? url : type === 'casino' ? 'casinos' : 'slots'
                  }
                  type={seeMoreType}
                />
              )}
            </div>
          </div>

          <div className={styles.swiper_btn_prev}>
            <PrevButton onClick={scrollPrev} disabled={prevBtnDisabled} />
          </div>
          <div className={styles.swiper_btn_next}>
            <NextButton onClick={scrollNext} disabled={nextBtnDisabled} />
          </div>

          {!nextBtnDisabled && (
            <div
              className={clsx({
                [styles.background_right]: true,
                [styles.background_slot_height]: type === 'slot',
              })}
            />
          )}
        </div>
      </section>
    </>
  );
};
