import clsx from 'clsx';

import { Icon } from 'common/icon';
import { Header } from 'components/slider/header';
import { useTranslation } from 'react-i18next';

import { ISlot } from 'constants/types';

interface SliderRowProps {
  btn?: boolean;
  name?: string;
  data: ISlot[];
  count?: string;
  title?: string;
  hasIcon?: boolean;
  guides?: string[];
  hasFooter?: boolean;
  description?: string;
  type?: 'mini' | 'medium';
  hasFavoritesActions?: boolean;
  container?: 'mini' | 'medium';
}

import { Loader } from 'common';
import { BlurHashImage } from 'components/blurhash-image';
import { LogoOverlay, LogoOverlayWrapper } from 'components/logo-overlay';
import { Routes } from 'constants/routes';
import { useFavorites } from 'hooks/useFavorites';
import Link from 'next/link';
import { getItemIdentifier } from 'utils/slug';
import styles from './styles.module.css';
import { GuideItem } from 'components/guide-item';

export const Card = ({
  data,
  hasIcon,
  hasFooter,
  hasFavoritesActions,
}: {
  data: ISlot | null;
  hasIcon?: boolean;
  hasFooter?: boolean;
  hasFavoritesActions?: boolean;
}) => {
  const { t } = useTranslation();

  const { removeFromFavorite, isLoading } = useFavorites();

  if (!data) {
    return null;
  }

  return (
    <div className={clsx(styles.cards__card, styles.slot_card)}>
      <Link key={data._id} href={Routes.slotPage(getItemIdentifier(data))}>
        <div className={styles.slot_card__wrapper}>
          <div className={styles.slot_card__cover}>
            <LogoOverlayWrapper>
              <BlurHashImage
                width={180}
                height={180}
                alt={data?.name}
                src={data?.imageUrl}
                blurhash={data?.blurHash}
              />

              <LogoOverlay
                width={180}
                height={180}
                alt={data?.name}
                image={data?.logoUrl}
              />
            </LogoOverlayWrapper>
          </div>
          {!hasIcon && (
            <div className={styles.slot_card__wins}>
              <Icon.Trophy />
              <span>{data.bigwinsCount} big wins</span>
            </div>
          )}
          {!hasFooter && (
            <div className={styles.slot_card__name}>{t(data?.name)}</div>
          )}
        </div>

        {hasFavoritesActions && (
          <div className={styles.favorite_action_shadow} />
        )}
      </Link>
      {hasFavoritesActions && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            removeFromFavorite({ favoriteId: data?._id });
          }}
          className={styles.wrapper_favorite_action}
        >
          {isLoading ? <Loader size="medium" /> : <Icon.Remove />}
        </div>
      )}
    </div>
  );
};

export const CardsMini = ({
  data,
  name,
  count,
  guides,
  hasIcon,
  hasFooter,
  title = '',
  description,
  hasFavoritesActions,
  container = 'medium',
}: SliderRowProps) => {
  const { t } = useTranslation();

  const containerStyles = clsx({
    [styles.container]: container === 'medium',
    [styles.container_s]: container === 'mini',
  });

  return (
    <section className={styles.cards}>
      <div className={containerStyles}>
        {title && (
          <div className={styles.cards_header_container}>
            {title && <h1 className={styles.cards__title}>{t(title)}</h1>}
            {description && (
              <h3 className={styles.cards__description}>{description}</h3>
            )}
            <div className={styles.cards_guides_container}>
              {guides?.map((id) => <GuideItem id={id} />)}
            </div>
          </div>
        )}
        <Header name={name} count={count} url={Routes.allSlots} />
        <div className={styles.cards__row}>
          {data?.map((item) => (
            <Card
              data={item}
              key={item?._id}
              hasIcon={hasIcon}
              hasFooter={hasFooter}
              hasFavoritesActions={hasFavoritesActions}
            />
          ))}
        </div>
      </div>
    </section>
  );
};
