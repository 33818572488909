import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { Icon } from 'common/icon';
import { Button } from 'common/new-button';

import { Routes } from 'constants/routes';
import { useIsClient } from 'usehooks-ts';

import styles from './styles.module.css';

export const TitleHome = ({ isLogged }: { isLogged: boolean }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const isClient = useIsClient();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          <h1 className={styles.title}>
            {t('Best Casino Ratings, Reviews, RTP indexes')}
          </h1>
          <span className={styles.desc}>
            {t(
              'RTPi is the worlds best source for casinos, slots, casino ratings and slots RTP'
            )}
          </span>
        </div>

        {isClient && !isLogged && (
          <div className={styles.wrapper_btn}>
            <Button
              icon={<Icon.UserSecond />}
              text="Create account free"
              className={clsx(styles.add_slot__btn)}
              onClick={() => router.push(Routes.login)}
            />
          </div>
        )}
      </div>
    </div>
  );
};
