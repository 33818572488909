import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { IBlogPost } from 'constants/types';

import { formatCreatedAtDate } from 'utils/formatDays';

import { Icon } from 'common/icon';

import { Routes } from 'constants/routes';
import Link from 'next/link';
import { useMemo } from 'react';
import { getItemIdentifier } from 'utils/slug';

import dynamic from 'next/dynamic';

import SliderPostSkeleton from 'components/slider/embla-home/post/loader';

import styles from './styles.module.css';

const PostsCarousel = dynamic(
  () => import('components/slider/embla-home/post'),
  { ssr: false, loading: () => <SliderPostSkeleton /> }
);

export const Post = ({ data }: { data: IBlogPost }) => {
  // const { t } = useTranslation();

  return (
    <Link
      href={Routes.blogPage(getItemIdentifier(data))}
      key={data?._id?.toString()}
      className={styles.posts__post}
    >
      <div className={styles.post_card__wrapper}>
        <div className={styles.post_card__image}>
          <Image
            width={100}
            height={100}
            src={data?.imageUrl ?? ''}
            alt={`slider-${data._id}`}
          />
        </div>

        <div className={styles.post_card__content}>
          <div className={styles.post_card__date}>
            {formatCreatedAtDate(data.last_modified)}
          </div>
          <div className={styles.post_card__link}>{data.title}</div>
        </div>

        <div className={styles.post_card__arrow}>
          <Icon.Arrow width={30} height={30} fillOpacity="0.4" />
        </div>
      </div>
    </Link>
  );
};

export const Slide = ({ item }: { item: IBlogPost }) => {
  const router = useRouter();
  const { t } = useTranslation();

  const handleBlogPost = () => {
    router.push(Routes.blogPage(getItemIdentifier(item)));
  };

  return (
    <div
      className={clsx(styles.posts__slide, styles.slide, styles.swiper_slide)}
    >
      <div className={styles.slide__wrapper}>
        <Image
          width={840}
          height={450}
          alt={item.title}
          objectFit="cover"
          src={item?.imageUrl ?? ''}
          className={styles.slide__wrapper}
        />
        <div className={styles.slide__content}>
          <div className={styles.slide__row}>
            <div className={styles.slide__title}>{item.title}</div>
            <div className={styles.slide__btn} onClick={handleBlogPost}>
              <Icon.ReadPost />
              <span>{t('Read post')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PostsRow = ({ data }: { data: IBlogPost[] }) => {
  return (
    <div className={clsx(styles.posts__items, styles.posts__items_row)}>
      {data?.map((item: IBlogPost) => <Post data={item} key={item._id} />)}
    </div>
  );
};

export const CarouselPost = ({
  type,
  posts,
  isBlog,
  countPosts,
  isSliderVisible = true,
}: {
  type?: 'casino' | 'slot' | 'posts';
  isBlog?: boolean;
  countPosts?: number;
  posts?: IBlogPost[];
  isSliderVisible?: boolean;
}) => {
  const router = useRouter();
  const { t } = useTranslation();

  const data = posts ?? [];

  const dataSlider = useMemo(() => {
    const index = 'how-to-choose-a-casino-based-on-rtpi-a-complete-guide';
    const whatIsRtpiPost = data?.find((item) => item?.slug === index);

    if (whatIsRtpiPost) {
      return [
        whatIsRtpiPost,
        ...data?.filter((item) => item?.slug !== index),
      ]?.slice(0, 4);
    }

    return data?.slice(0, 4);
  }, [data?.length, posts?.length]);

  return (
    <section
      className={clsx(styles.posts, {
        [styles.posts_blog]: isBlog,
      })}
    >
      <div className={styles.container}>
        <div className={styles.posts__row}>
          {isSliderVisible && (
            <>
              <PostsCarousel
                type={type}
                slides={dataSlider}
                options={{ loop: true }}
              />

              <div className={styles.posts__column}>
                <div className={styles.posts__items}>
                  {data
                    .slice(0, 3)
                    ?.map((item: IBlogPost) => (
                      <Post data={item} key={item._id} />
                    ))}
                </div>

                {!isBlog && (
                  <div
                    className={clsx(styles.posts__all_link, styles.link)}
                    onClick={() => router.push(Routes.blogPosts)}
                  >
                    <div
                      className={styles.link__name}
                      onClick={() => router.push(Routes.blogPosts)}
                    >
                      {t('All posts')}
                    </div>
                    <div className={styles.link__count}>{countPosts ?? 0}</div>
                    <Icon.Arrow width={30} height={30} />
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {isBlog && (
          <PostsRow data={data.slice(isSliderVisible ? 3 : 0, data?.length)} />
        )}
      </div>
    </section>
  );
};
