import Link from 'next/link';
import Image from 'next/image';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { IProvider } from 'constants/types';
import { Routes } from 'constants/routes';

import { Icon } from 'common/icon';
import { getItemIdentifier } from 'utils/slug';

import styles from './styles.module.css';

export const ProviderCard = ({
  key,
  data,
}: {
  key: string;
  data: IProvider;
}) => {
  const { t } = useTranslation();

  const renderIcon = useCallback(
    (counts: number) => {
      if (counts > 10) {
        return <Icon.Smile width="16" height="16" />;
      } else {
        return <Icon.Smile width="16" height="16" />;
      }
    },
    [data]
  );

  return (
    <div key={key} className={styles.container_provider}>
      <Link key={data._id} href={Routes.provider(getItemIdentifier(data))}>
        <div className={styles.wrapper_provider}>
          <div className={styles.image}>
            <Image
              width={160}
              height={50}
              alt={data?.name}
              src={data?.imageUrl ?? ''}
            />
          </div>

          <div className={styles.wrapper_desc}>
            {renderIcon(data?.slotsCount)}
            <span>
              {data?.slotsCount ?? 0} {t('slots')}
            </span>
          </div>

          <div className={styles.provider_card__name}>{data?.name}</div>
        </div>
      </Link>
    </div>
  );
};

export const CollectionProviders = ({ data }: { data: IProvider[] }) => {
  return (
    <section>
      <div className={styles.providers_row}>
        {data?.map((provider) => (
          <ProviderCard key={provider?._id?.toString()} data={provider} />
        ))}
      </div>
    </section>
  );
};
